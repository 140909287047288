import React from "react";

import { ReactComponent as MaestroIcon } from "../assets/img/icons/Maestro.svg";
import { ReactComponent as MastercardIcon } from "../assets/img/icons/Mastercard.svg";
import { ReactComponent as VisaIcon } from "../assets/img/icons/Visa.svg";
import { ReactComponent as JCBIcon } from "../assets/img/icons/JCB.svg";
import { ReactComponent as HeadphonesIcon } from "../assets/img/icons/headphones-support.svg";
import { ReactComponent as ShieldProtectedCheckmarkIcon } from "../assets/img/icons/Shield-Protected-Checkmark.svg";
import { ReactComponent as LockIcon } from "../assets/img/icons/Lock.svg";
import { ReactComponent as GoogleIcon } from "../assets/img/img-09-10-23/ico-google.svg";
import { ReactComponent as StarIcon } from "../assets/img/img-09-10-23/ico-star.svg";
import { ReactComponent as TrustIcon } from "../assets/img/img-09-10-23/ico-trust.svg";
import { ReactComponent as TrustStarIcon } from "../assets/img/img-09-10-23/ico-truststar.svg";

export const Guarantees = ({ row = true }) => {
  return (
    <div
      className={
        row
          ? "card card-border card-sm-mob card-24 r-4"
          : "card card-border card-sm-mob card-20 r-4"
      }
    >
      <div className="card-body p-0">
        <div
          className={
            row
              ? "row gutters-x-16 row-granty mb--16"
              : "row gutters-x-16 mb--16"
          }
        >
          <div
            className={
              row
                ? "col-lg-6 col-md-12 d-flex flex-column max-w-max mb-16 order-1-mob"
                : "col-lg-12 d-flex flex-column max-w-max mb-16 order-1-mob"
            }
          >
            <div className="paymant-info paymant-info--column p-0 border-none">
              <ul className="paymant-info__list paymant-info--column">
                <li>
                  <span className="ico me-2">
                    <LockIcon />
                  </span>
                  <div>
                    Guaranteed <span className="fw-600">safe &amp; secure</span>{" "}
                    checkout
                  </div>
                </li>
                <li>
                  <span className="ico me-2">
                    <ShieldProtectedCheckmarkIcon />
                  </span>
                  <div>
                    Secured <span className="fw-600"> SSL Checkout</span>
                  </div>
                </li>
                <li>
                  <span className="ico me-2">
                    <HeadphonesIcon />
                  </span>
                  <div>
                    <span className="fw-600">24/7 Support</span>
                  </div>
                </li>
              </ul>
              <ul className="paymant-info__cards">
                <li>
                  <MaestroIcon />
                </li>
                <li>
                  <MastercardIcon />
                </li>
                <li>
                  <VisaIcon />
                </li>
                <li>
                  <JCBIcon />
                </li>
              </ul>
            </div>
          </div>
          <div
            className={
              row
                ? "col-lg-6 col-md-12 d-flex flex-column flex-auto mb-16"
                : "col-12 d-flex flex-column flex-auto mb-16"
            }
          >
            <div className="rating-blocks h-full">
              <div className="rating-block--item h-full">
                <div className="rating-block">
                  <div>
                    <GoogleIcon />
                  </div>
                  <div className="emit-rating">
                    {Array.from({ length: 5 }, (_, index) => (
                      <div className="emit-rating--item ico ico-15" key={index}>
                        <StarIcon />
                      </div>
                    ))}
                  </div>
                  <ul className="rating-list">
                    <li>
                      <span>5.0</span> rating
                    </li>
                    <li>
                      <span>231</span> review
                    </li>
                  </ul>
                </div>
              </div>
              <div className="rating-block--item h-full mb-16">
                <div className="rating-block">
                  <div>
                    <TrustIcon />
                  </div>
                  <div className="emit-rating--1">
                    <div className="emit-rating--item ico ico-15">
                      <TrustStarIcon />
                    </div>
                    {Array.from({ length: 5 }, (_, index) => (
                      <div className="emit-rating--item ico ico-15" key={index}>
                        <TrustStarIcon />
                      </div>
                    ))}
                  </div>
                  <ul className="rating-list">
                    <li>
                      <span>5.0</span> rating
                    </li>
                    <li>
                      <span>446</span> review
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
