import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import { DatePickerCustom } from "../../../components/ui/DatePickerCustom";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../helpers/index";

import { ReactComponent as CalendarIcon } from "../../../assets/img/icons/Calendar-Schedule.svg";
import { ReactComponent as LocationIcon } from "../../../assets/img/location-mark.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/img/icons/times.svg";
import { ReactComponent as SearchIcon } from "../../../assets/img/icons/search-icon-black.svg";
import { fetchCourses } from "../../../redux/thunks/coursesThunk";
import { clearSearchResult } from "../../../redux/slices/coursesSlice";
import { YourLocationModal } from "../../../components/modals/YourLocationModal";
import { BookCourseModal } from "../../../components/modals/BookCourseModal";

export const SearchCourse = () => {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses.courses);
  const { p_code } = useParams();
  const [searchParams, setSearchParams] = useState({
    location: "",
    start_date: new Date(),
  });

  const [bookCourseModalOpen, setBookCourseModalOpen] = useState(false);
  const [searchError, setSearchError] = useState(null);

  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const suggestedLocations = ["London", "Edinburgh", "Birmingham", "Glasgow"];

  const navigate = useNavigate();

  const geocodeSearch = async (location) => {
    setSearchError(null);

    if (!location || location.length === 0) {
      dispatch(fetchCourses({ p_code: p_code ? p_code : "DOM-PAK-REA" }));
      return false;
    }
    const geocoder = new window.google.maps.Geocoder();
    let result = await new Promise((res, rej) => {
      geocoder.geocode(
        {
          address:
            location.toLowerCase() === "liverpool"
              ? `${location}, UK`
              : location,
        },
        function (results, status) {
          if (status === "OK") {
            let loc = results[0].geometry.location;
            res({ success: true, lat_lng: [loc.lat(), loc.lng()] });
          } else {
            res({ success: false, message: status });
          }
        },
      );
    });

    if (result.success) {
      dispatch(
        fetchCourses({
          p_code: p_code || "DOM-PAK-REA",
          user_cord: `&lat=${result.lat_lng[0]}&lng=${result.lat_lng[1]}`,
        }),
      );
    } else if (result.success === false && result.message === "ZERO_RESULTS") {
      setSearchError(
        "Location not found - try a different post code or town/city",
      );
      dispatch(
        fetchCourses({ p_code: p_code || "DOM-PAK-REA", not_found: true }),
      );
    } else {
      dispatch(
        fetchCourses({
          p_code: p_code || "DOM-PAK-REA",
        }),
      );
    }

    return result;
  };

  const searchServicesHandler = async () => {
    if (!searchParams?.location) {
      setIsError(true);
      return;
    }
    navigate({
      search: `?location=${searchParams.location}&start_date=${new Date(
        searchParams?.start_date,
      ).getTime()}`,
    });

    await geocodeSearch(searchParams.location);
    setIsError(false);
  };

  const resetSearch = () => {
    dispatch(clearSearchResult());
    setSearchParams({
      location: "",
      start_date: new Date(),
    });
  };

  const modalSearchHandler = async (data) => {
    navigate({
      search: `?location=${data?.user_city}&start_date=${new Date(
        searchParams?.start_date,
      ).getTime()}`,
    });
    setSearchParams({
      location: data?.user_city,
      start_date: new Date(),
    });
    let result = await geocodeSearch(data?.user_city);

    return result;
    // setLocationModalOpen(false);
  };

  return (
    <>
      {courses.length ? (
        <section className="section-dell-2">
          <div className="container container-900">
            <div className="radius-10 bg-white box-shadow2 mb-30 top-md-0 overflow-hidden">
              <div className="filters-block px-20">
                <div className="filters-block-row filters-block-row--12">
                  <div className="filters-block__item">
                    <div className="form-group max-w-350">
                      <label className="wrapp-input">
                        <span className="ico ico-20 mx-12">
                          <SearchIcon />
                        </span>
                        <div className="input px-40" type="search">
                          <div className="input-find">
                            <ul className="list-country">
                              <li>
                                <div className="link-counrty">
                                  {searchParams?.location}
                                </div>
                              </li>
                            </ul>
                            <div className="find-date">
                              {formatDate(searchParams?.start_date)}
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn"
                          onClick={resetSearch}
                        >
                          <span className="ico ico-12">
                            <RemoveIcon />
                          </span>
                        </button>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-add">
                <div className="d-flex flex-column">
                  <h5 className="heading-5">
                    This course is also available onsite.
                  </h5>
                  <p className="def-text-1 color-text--600">
                    Our in-house first aid training is fully accredited by the
                    First Aid Industry Body (FAIB).
                  </p>
                </div>
                <button
                  className="btn btn-success
                   btn--md radius-5 ttu w-full-mob min-w-187"
                  onClick={(e) => {
                    e.preventDefault();
                    setBookCourseModalOpen(true);
                  }}
                >
                  Book onsite
                </button>
              </div>
            </div>
          </div>

          {bookCourseModalOpen ? (
            <BookCourseModal
              isOpen={bookCourseModalOpen}
              close={() => setBookCourseModalOpen(false)}
            />
          ) : null}
        </section>
      ) : (
        <>
          <section className="section-dell">
            <div className="container container-900">
              <div className="radius-10 bg-white box-shadow2 mb-30 top-md-0">
                <div className="filters-block px-20">
                  <div className="filters-block-row filters-block-row--12">
                    <div className="filters-block__item">
                      <div className="form-group form-group--bottom">
                        <label className="form-group-title">
                          Your Location{" "}
                        </label>
                        <label className="wrapp-input">
                          <span className="ico ico-20 mx-12">
                            <LocationIcon />
                          </span>
                          <input
                            className={`input pl-40 ${
                              isError && !searchParams?.location ? "error" : ""
                            }`}
                            type="text"
                            placeholder="Type your location"
                            value={searchParams.location}
                            onChange={(e) => {
                              setSearchError(null);
                              setSearchParams((prev) => ({
                                ...prev,
                                location: e.target.value,
                              }));
                            }}
                          />
                        </label>
                        <ul className="list-locations mt-2">
                          {suggestedLocations.map((location, idx) => (
                            <li
                              className="location-item"
                              key={idx}
                              onClick={(e) =>
                                setSearchParams((prev) => ({
                                  ...prev,
                                  location,
                                }))
                              }
                            >
                              <button className="btn tdu">
                                <span className="text-1">{location}</span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="filters-block__item">
                      <div className="form-group form-group--bottom form-group--bottom-mob-none">
                        <label className="form-group-title">Date</label>
                        <label className="d-flex input align-items-center">
                          <CalendarIcon />
                          <DatePickerCustom
                            className="datapicker px-10"
                            value={new Date()}
                            onChange={(date) =>
                              setSearchParams((prev) => ({
                                ...prev,
                                start_date: date,
                              }))
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="filters-block__item mw-188 align-self-end">
                      <div className="form-group form-group--bottom form-group--bottom-mob-none relative">
                        <button
                          onClick={searchServicesHandler}
                          className="btn btn-success btn--md radius-5 ttu w-100"
                        >
                          Search
                        </button>
                        {/* { (searchError && !locationModalOpen) && <div className="error-badge">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_610_10855)">
                              <path d="M16.6498 13.2251L10.5009 2.54677C10.3473 2.28515 10.1279 2.06823 9.86463 1.91751C9.60131 1.76679 9.30318 1.6875 8.99978 1.6875C8.69638 1.6875 8.39824 1.76679 8.13493 1.91751C7.87161 2.06823 7.65226 2.28515 7.49861 2.54677L1.34978 13.2251C1.20194 13.4782 1.12402 13.766 1.12402 14.059C1.12402 14.3521 1.20194 14.6399 1.34978 14.8929C1.50146 15.1561 1.72044 15.3742 1.98425 15.5249C2.24806 15.6755 2.54719 15.7532 2.85095 15.75H15.1486C15.4521 15.753 15.751 15.6751 16.0145 15.5245C16.278 15.3739 16.4968 15.1559 16.6484 14.8929C16.7964 14.64 16.8746 14.3523 16.8748 14.0592C16.8751 13.7662 16.7974 13.4783 16.6498 13.2251ZM8.43728 7.31255C8.43728 7.16336 8.49654 7.02029 8.60203 6.9148C8.70752 6.80931 8.85059 6.75005 8.99978 6.75005C9.14896 6.75005 9.29204 6.80931 9.39753 6.9148C9.50301 7.02029 9.56228 7.16336 9.56228 7.31255V10.125C9.56228 10.2742 9.50301 10.4173 9.39753 10.5228C9.29204 10.6283 9.14896 10.6875 8.99978 10.6875C8.85059 10.6875 8.70752 10.6283 8.60203 10.5228C8.49654 10.4173 8.43728 10.2742 8.43728 10.125V7.31255ZM8.99978 13.5C8.8329 13.5 8.66977 13.4506 8.53102 13.3578C8.39226 13.2651 8.28412 13.1334 8.22025 12.9792C8.15639 12.825 8.13968 12.6554 8.17224 12.4917C8.2048 12.328 8.28516 12.1777 8.40316 12.0597C8.52116 11.9417 8.6715 11.8613 8.83517 11.8288C8.99884 11.7962 9.16849 11.8129 9.32267 11.8768C9.47684 11.9406 9.60862 12.0488 9.70133 12.1875C9.79404 12.3263 9.84353 12.4894 9.84353 12.6563C9.84353 12.8801 9.75463 13.0947 9.5964 13.2529C9.43816 13.4112 9.22355 13.5 8.99978 13.5Z" fill="white"/>
                            </g>
                            <defs>
                              <clipPath id="clip0_610_10855">
                                <rect width="18" height="18" fill="white"/>
                              </clipPath>
                            </defs>
                          </svg>
                          <span>{searchError}</span>
                        </div> } */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              <YourLocationModal
                isOpen={locationModalOpen}
                close={() => setLocationModalOpen(false)}
                submit={modalSearchHandler}
                setOpen={setLocationModalOpen}
              />
            }
          </section>
        </>
      )}
    </>
  );
};
